import _ from 'lodash';

export const validateEmail = (value) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
};

export const validatePassword = (value) => {
  if (!value) return true;
  if (value.length < 6) return false;
  if (value.search(/[a-z]/i) < 0) return false;
  if (value.search(/[0-9]/) < 0) return false;
  return true;
};

export const validatePhone = (value) => {
  // eslint-disable-next-line
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
};

export const validatePlace = (value, key, values) => {
  const place = _.get(values, key + '_place');
  console.log('PLACE VALIDATE', value, place?.formatted);
  if (place?.formatted === value) return true;
  return false;
};

export const generateInitialValues = (FORM, skipUndefined = false) => {
  const arr = {};
  FORM.forEach((row) => {
    row.items.forEach((f) => {
      if (f.defaultValue) arr[f.value] = f.defaultValue;
      else if (!skipUndefined) arr[f.value] = undefined;
    });
  });
  return arr;
};

export const deriveFormInitialValues = (FORM, item) => {
  if (!FORM || !item) return {};

  const fields = flattenForm(FORM);
  return fields.reduce((acc, input) => {
    const key = input.value;
    const fieldValue = _.get(item, key, null);
    _.set(acc, key, fieldValue);
    return acc;
  }, {});
};

export const flattenForm = (FORM, values: any = false) => {
  const arr: Array<any> = [];
  FORM.forEach((row) => {
    if (values && row.isHidden && row.isHidden(values)) return;
    row.items.forEach((f) => {
      if (values && f.isHidden && f.isHidden(values)) return;
      arr.push(f);
    });
  });
  return arr;
};

export const insertEmptyOption = (options, text = '', value: any = 0) => {
  return [{ labelText: text, value }, ...options];
};

export const appendEmptyOption = (options, text = '', value: any = 0) => {
  return [...options, { labelText: text, value }];
};

export const placeValidation = {
  condition: (v, key, values) => !validatePlace(v, key, values),
  message: 'INVALID_ADDRESS',
};

export const passwordValidation = {
  condition: (v) => !validatePassword(v),
  message: 'INVALID_PASSWORD',
};

export const requiredValidation = {
  condition: (v) => v === '' || v === null || v === undefined,
  message: 'REQUIRED',
};

export const amountValidation = {
  condition: (v) => v === null || v === undefined || Number(v) === 0,
  message: 'REQUIRED',
};

export const validateError = (FORM, values) => {
  const fields = flattenForm(FORM, values).filter(
    (f) => f.validation && (!f.validationCondition || f.validationCondition(values)),
  );

  const errors = fields.reduce((acc, input) => {
    const key = input.value;
    const fieldValue = _.get(values, key, null);
    const failedValidation = input.validation.find((v) => v.condition(fieldValue, key, values));
    if (failedValidation) {
      _.set(acc, key, failedValidation.message);
    }
    return acc;
  }, {});

  // console.log('VALUES', values);
  return errors;
};

export const getDiff = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
};

export const getEl = (selector) => {
  const doc = document;
  if (doc) {
    const el = doc.querySelector(selector) as any;
    if (el) return el;
  }
  return null;
};

export const scrollTo = (fromSelector, toSelector) => {
  const container: any = getEl(fromSelector);
  if (!container) return;
  const destination = container.querySelector(toSelector);
  if (!destination) return;
  container.scrollTop = destination.offsetTop;
};

export const getEls = (selector) => {
  const doc = document;
  if (doc) {
    const el = doc.querySelectorAll(selector) as any;
    if (el) return el;
  }
  return null;
};

export const stringToHtml = (str) => {
  if (!str) return '';
  if (typeof str !== 'string') return '';
  const html = str.replaceAll('\n', '<br>');
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
