import _ from 'lodash';
import React from 'react';
import { Box, FormControl, Grid, TextFieldProps } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';
import throttle from 'lodash/throttle';
import { configs } from '$configs';
import { Field } from 'formik';
import intl from '$gintl';

import { BSLabel, TextFieldWrapper, AutoCompleteWrapper } from './styles';
import { sleep } from '$gbusiness/helpers/util';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService: { current: any } = { current: null };
const placeService: { current: any } = { current: null };

interface PlaceType {
  description: string;
  place_id: string;
  distance_meters?: number;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

interface GoogleAddressProps {
  label?: string;
  labelText?: string;
  name: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  position?: TextFieldProps['variant'];
  fullWidth?: boolean;
  formik?: any;
  className?: string;
  size?: string;
  shrink?: boolean;
  handleChange?: (e, v) => void;
  handleBlur?: Function;
  requestOptions?: any;
  autoSelect?: boolean;
}

const GoogleAddress: React.FC<GoogleAddressProps> = ({
  label = '',
  labelText = '',
  name = '',
  readonly = false,
  disabled = false,
  placeholder = '',
  className = '',
  position = undefined,
  fullWidth = false,
  formik = {},
  size = configs.display.inputSize,
  shrink = undefined,
  handleChange = (e) => {},
  handleBlur = () => {},
  autoSelect = true,
  requestOptions = {},
}) => {
  // const classes = useStyles();
  const [value, setValue] = React.useState<any>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);

  const defaultRequestOptions = {
    componentRestrictions: { country: 'us' },
    types: ['geocode'],
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    const googleURI = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    if (!document.querySelector('#google-maps')) {
      loadScript(googleURI, document.querySelector('head'), 'google-maps');
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 500),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google && (window as any).google.maps.places) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
      placeService.current = new (window as any).google.maps.places.PlacesService(
        document.createElement('div'),
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        ...defaultRequestOptions,
        ...requestOptions,
        input: inputValue,
      },
      (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;
  const inputSize = size === 'small' || size === 'xsmall' ? 'small' : 'medium';

  const labelProps = position
    ? {
        label: labelText || intl(label),
      }
    : {};
  const variant = position || 'outlined';

  const onChange = (e) => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e, null);
  };

  const onBlur = (e) => {
    // if (formik.handleBlur) formik.handleBlur(e);
    if (handleBlur) handleBlur(e);
  };

  // const FormField = disableFastField ? Field : FastField;
  const FormField = Field;
  const varPlace = `${name}_place`;

  return (
    <AutoCompleteWrapper
      fullWidth
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      className={className}
      autoComplete
      autoSelect={autoSelect}
      disabled={disabled}
      value={_.get(formik.values, name)}
      popupIcon={null}
      onChange={async (event: any, newValue: PlaceType | null) => {
        if (!newValue) return;
        const { place_id, distance_meters, description } = newValue;
        await sleep(10);
        // setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        formik.setFieldValue(name, description);
        formik.setFieldValue(varPlace, {
          placeId: place_id,
          formatted: description,
        });

        if (handleChange) {
          handleChange(null, {
            ...newValue,
            ...(distance_meters && { distance_miles: (distance_meters || 0) * 0.000621371 }),
          });
        }

        // placeService.current.getDetails({ placeId: place_id }, (response) => {
        //   setOptions(newValue ? [newValue, ...options] : options);
        //   setValue(newValue);
        //   formik.setFieldValue(name, response.formatted_address);
        //   formik.setFieldValue(description);
        //   formik.setFieldValue(varPlace, {
        //     placeId: place_id,
        //     html: response.adr_address,
        //     formatted: response.formatted_address,
        //     lat: response.geometry.location.lat(),
        //     lng: response.geometry.location.lng(),
        //   });
        //   if (handleChange)
        //     handleChange(null, {
        //       ...newValue,
        //       ...(distance_meters && { distance_miles: (distance_meters || 0) * 0.000621371 }),
        //     });
        // });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {option.structured_formatting.main_text}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderInput={(params) => {
        const more = {
          ...params,
        };
        return (
          <FormField name={name}>
            {({ form }) => (
              <FormControl fullWidth>
                {!position && (
                  <BSLabel className="bs-label" shrink={true}>
                    {labelText || intl(label)}
                  </BSLabel>
                )}
                <TextFieldWrapper
                  {...labelProps}
                  type="text"
                  className={inputSize}
                  name={name}
                  onInput={onChange}
                  onBlur={onBlur}
                  placeholder={intl(placeholder) || undefined}
                  size={inputSize}
                  variant={variant}
                  error={hasError}
                  helperText={errorMsg}
                  value={_.get(form.values, name) || ''}
                  {...more}
                />
                <input type="hidden" name={varPlace} />
              </FormControl>
            )}
          </FormField>
        );
      }}
    />
  );
};

export default GoogleAddress;
