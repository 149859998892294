import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import { sleep, toNumber } from '$gbusiness/helpers/util';

export const CREDIT_FORM = (initialCredit): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.CREDIT.COLS.DATE',
        value: 'creditDate',
        input: INPUT.DATE,
        gridSize: 4,
      },
      {
        ...INPUT_TYPES.AMOUNT,
        label: 'SCREEN.CREDIT.COLS.AMOUNT',
        value: 'total',
        gridSize: 4,
        onChange: async ({ value, formik, setFieldValue }) => {
          const diff = toNumber(value) - (initialCredit.total || 0);
          setFieldValue('balance', toNumber(initialCredit.balance) + diff);
          await sleep(100);
          formik.validateForm();
        },
        validation: [
          {
            condition: (v) => {
              if (toNumber(v) < 0) return true;
              const diff = toNumber(v) - (initialCredit.total || 0);
              const newBalance = toNumber(initialCredit.balance) + diff;
              if (newBalance < 0) return true;
              return false;
            },
            message: 'CREDIT_AMOUNT',
          },
        ],
      },
      {
        ...INPUT_TYPES.AMOUNT,
        label: 'SCREEN.CREDIT.COLS.BALANCE',
        value: 'balance',
        disabled: true,
        gridSize: 4,
      },
      {
        input: INPUT.TEXTAREA,
        label: 'SCREEN.CREDIT.COLS.MEMO',
        value: 'memo',
        gridSize: 12,
      },
    ],
  },
];
