import { validateEmail, validatePhone } from '$gbusiness/helpers/input';
import React from 'react';

export const emailLink = (email, text = null) => {
  if (!validateEmail(email)) return email;

  const linkText = text || email;
  return <a href={`mailto:${email}`}>{linkText}</a>;
};

export const phoneLink = (phone, text = null) => {
  if (!validatePhone(phone)) return phone;

  const linkText = text || phone;
  return <a href={`tel:${phone}`}>{linkText}</a>;
};

export const addressNode = (address) => {
  return (
    <>
      {address && (
        <div>
          <div>{address.street}</div>
          <div>{`${address.city || ''}, ${address.state || ''} ${address.zip || ''}`}</div>
        </div>
      )}
    </>
  );
};

export const mobileLink = phoneLink;
